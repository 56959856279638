<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>
			
			<div class="w-100 h-100 position-relative overflow-hidden">

				<div class="w-100 h-100 d-flex">

					<div class="d-flex flex-column w-100 h-100">

						<workspace_nav v-if="workspace" :label="'Delete ' + workspace.name" />
			
						<div class="container h-100 py-4">
							
							<div class="row h-100">

								<div class="col-12">

									<loader v-if="workspace_loading" />

									<div v-else-if="workspace">

										<div class="row h-100 justify-content-center">

											<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

												<div class="card">

													<div class="card-body">

														<form @submit.prevent="submit">

															<p class="alert alert-danger">
																This action can't be reversed so please be sure before proceeding. Deleting your workspace will also delete all related sites and be removed from your monthly billing.
															</p>

															<p v-if="error.message" class="alert alert-danger text-start">
																{{ error.message }}
															</p>

															<p>The workspace reference is: {{ workspace.domain }}</p>

															<form_control
																label="Enter workspace reference"
																type="password"
																name="password"
																:placeholder="workspace.domain"
																:required="true"
																:error="error"
																v-model="password"
															/>

															<btn_submit 
																:label="'Delete ' + workspace.name + ' workspace'" 
																icon="cancel"  
																:icon_after="true" 
																:loading="loading" 
															/>

														</form>

													</div>

												</div>

											</div>

										</div>

									</div>

									<div v-else-if="!workspace">

										<div class="row h-100 justify-content-center">

											<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

												<div class="card">

													<div class="card-body text-center">

														<h1>There was an issue.</h1>

														<p v-if="error.message" class="alert alert-danger text-start">
															{{ error.message }}
														</p>

														<btn_router_link 
															label="View all workspaces" 
															:route="{ name: 'workspaces' }" 
															icon="workspaces" 
														/>

													</div>

												</div>

											</div>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import loader from '@/components/loader'
import main_nav from '@/components/main_nav'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import support_link from '@/components/support_link'
import workspace_nav from '@/components/workspace_nav'
import btn_router_link from '@/components/btn_router_link'
import workspace_service from '@/services/workspace_service'

export default {
	name: 'workspaces',

	components: {
		layout,
		loader,
		main_nav,
		form_control,
		btn_submit,
		support_link,
		btn_router_link,
		workspace_nav
	},

	data() {
		return {
			loading: false,
			workspace_loading: true,
			workspace: null,
			password: null,
			error: {}
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	mounted()
	{
		this.get_workspace()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_workspace()
		{
			this.reset_error()

			workspace_service.show( this.$route.params.workspace_id ).then(( response ) => {
				this.workspace = response.data

				if ( !this.workspace.is_created && !this.workspace.error_message ) {
					setTimeout(() => {
						this.get_workspace()
					}, 300000 )
				}
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.workspace_loading = false
			})
		},

		async submit()
		{
			this.loading = true 

			this.reset_error()

			workspace_service.destroy( this.$route.params.workspace_id, {
				password: this.password
			}).then(() => {
				this.$router.push( { name: 'workspaces' } )
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>

<style>

</style>
